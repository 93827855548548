/* src/styles.css */

.pcb-board {
    background-color: #004d00; /* Dark green */
    background-image: 
      linear-gradient(90deg, rgba(0, 128, 0, 0.2) 1px, transparent 1px), 
      linear-gradient(rgba(0, 128, 0, 0.2) 1px, transparent 1px);
    background-size: 20px 20px;
    position: relative; /* Ensure relative positioning for the canvas */
  }
  
  .pcb-silkscreen {
    color: #ffffff; /* White */
    font-family: "Courier New", Courier, monospace; /* Monospace font for PCB look */
    text-shadow: 0 0 5px #00ff00; /* Green glow effect */
  }
  
  canvas {
    pointer-events: none; /* Ensure canvas does not block mouse events */
  }
  